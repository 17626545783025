import React from 'react'
import FeaturedPostHero from '../components/page-components/FeaturesPost/FeaturesPostHero'
import FlexibleContent from '../components/reusable/FlexibleContent'
import NextPost from '../components/page-components/FeaturesPost/NextPost'
import { graphql } from "gatsby"
import {Styling} from '../styles/templates/features-post.styled'
import Seo from '../components/global/seo'

export default function FeaturesPost({data}) {

    return (
        <Styling>
          <Seo title={data.hero.seo.title} description={data.hero.seo.metaDesc} />
          <FeaturedPostHero hero={data.hero} />
          <FlexibleContent flexible={data.flexible} />
          {/* <NextPost nextPost={data.nextPost} /> */}
        </Styling>
    )
}


export const query = graphql`
    query{
        hero: wpPage(title: {eq: "Cookies"}) {
            title
            seo{
              metaDesc
              title
            }
            cookiesPrivacyIntroText{
                introTextCookiesPrivacy
            }
            acf_hero{
                heroImage{
                    localFile{
                        childImageSharp {
                            gatsbyImageData(
                                width: 3000
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
            }
        }
        flexible: wpPage(title: {eq: "Cookies"}) {
            flexibleContent {
                postBuilder {
                  ... on WpPage_Flexiblecontent_PostBuilder_TextBlock {
                    fieldGroupName
                    text
                  }
                  ... on WpPage_Flexiblecontent_PostBuilder_Quote {
                    fieldGroupName
                    quoteText
                  }
                  ... on WpPage_Flexiblecontent_PostBuilder_AudioPlayer {
                    fieldGroupName
                    trackTile
                    audioFile {
                      localFile {
                        publicURL
                      }
                    }
                  }
                  ... on WpPage_Flexiblecontent_PostBuilder_ImageBlock {
                    fieldGroupName
                    image {
                        localFile{
                            childImageSharp {
                              gatsbyImageData(
                                width: 1500
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                                layout: FULL_WIDTH
                              )
                            }
                          }
                    }
                  }
                  ... on WpPage_Flexiblecontent_PostBuilder_VideoBlock {
                    fieldGroupName
                    video
                  }
                }
              }
        }
    }
`